import Collapse from "bootstrap/js/src/collapse";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAVl6GBcjzRDFozgCA1eZr-9zqUkVgfkxE",
  authDomain: "bangkit-2023-landing.firebaseapp.com",
  projectId: "bangkit-2023-landing",
  storageBucket: "bangkit-2023-landing.appspot.com",
  messagingSenderId: "964842923779",
  appId: "1:964842923779:web:c987df59645b7983e5eb36",
  measurementId: "G-59VQ1T7T7G",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

document.querySelectorAll("[data-register-cta-btn]").forEach((button) => {
  button.addEventListener("click", () => {
    logEvent(analytics, "click_register_cta", {
      event_label: button.dataset.registerCtaBtn,
    });
  });
});

document.getElementById("partner-contact-btn").addEventListener("click", () => {
  logEvent(analytics, "click_partner_contact");
});
